// media querry variable
$break-desktop-min: 992px;
$break-large-xxl: 1600px;
$break-large-xl: 1300px;
$break-large: 1200px;
$break-tab: 991px;
$break-medium: 767px;
$break-small: 500px;

@mixin desktop-min {
  @media (min-width: $break-desktop-min) {
    @content;
  }
}

@mixin mobile-md {
  @media (max-width: $break-small) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $break-medium) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $break-tab) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: $break-large) {
    @content;
  }
}

@mixin desktop-xl {
  @media (max-width: $break-large-xl) {
    @content;
  }
}

@mixin desktop-xxl {
  @media (max-width: $break-large-xxl) {
    @content;
  }
}
