.d-flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-column {
  flex-direction: column;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-between {
  align-items: space-between;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
