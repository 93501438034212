/* native scrolbbar */

::-webkit-scrollbar-track {
  border-radius: 0.5rem;
  background-color: #e0e0e0;
}

::-webkit-scrollbar {
  width: 0.7rem;
  height: 0.7rem;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 0.3rem rgba(0, 0, 0, 0.3);
  background-color: #dadfe6;
}

* {
  scrollbar-color: light;
  scrollbar-width: thin;
  scrollbar-color: #dadfe6 #f5f5f5;
}

/* global style */

body {
  background: $main-bg;
  background-repeat: repeat-x;
  background-size: auto;
  background-position: left calc(100% + 100px);
  min-height: 100vh;
  padding-bottom: 90px;
  @include mobile {
    background-size: 150px;
    background-position: left calc(100% + 50px);
  }
}

// main layout
.main-layout {
  display: flex;
  .content-area {
    min-width: 0;
    flex: 1;
    &.logged-in {
      padding: 35px 30px;
      background-image: url("data:image/svg+xml,%3Csvg width='281' height='227' viewBox='0 0 281 227' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.02' fill-rule='evenodd' clip-rule='evenodd' d='M281 223.695V94.346C274.291 95.2094 267.452 95.6544 260.511 95.6544C195.299 95.6544 139.169 56.394 114.03 0H0V47.0213C0 70.7651 12.5403 92.7029 32.9101 104.575L227.601 218.096C243.99 227.648 263.43 229.515 281 223.695Z' fill='%230F0FD1'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: right top;
      margin-left: $sidebar-menu-width-open;
      @include transition(0.4);
      @include tablet {
        padding: 35px 30px;
        margin-left: 0;
      }
      &.menu-collapsed {
        margin-left: $sidebar-menu-width-closed;
        padding: 35px 60px;
        @include tablet {
          padding: 50px;
          margin-left: 16px;
        }
      }
    }
  }
  .main-navbar-close-layer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    z-index: 98;
    opacity: 0;
    visibility: hidden;
    display: none;
    @include transition(0.4);
    @include tablet {
      display: block;
    }
    &.mob-menu-active {
      opacity: 0.4;
      visibility: visible;
    }
  }
}
